import logo from './imag/WhatsApp Image 2024-02-01 at 19.56.44_35b975da.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Sorry, the website is under maintenance | عذرا الموقع الالكتروني تحت الصيانه
        </p>
      </header>
    </div>
  );
}

export default App;
